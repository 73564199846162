export { };

declare global {
  interface String {
    format(...replacements: string[]): string;
  }
}

String.prototype.format = function () {
  const args = arguments;
  const sprintfRegex = /\{(\d+)\}/g;

  const sprintf = function (match, number) {
    return number in args ? args[number] : match;
  };

  return this.replace(sprintfRegex, sprintf);
};
